import React from 'react';

import { Container, Title, NumbersList, Number } from './styles';

const Numbers = () => {
    return (
        <Container>

            <Title>
                <h1>Nossos Números</h1>
            </Title>

            <NumbersList>
                <Number>
                    <a>765 k</a>
                    <p>Comissão administrada</p>
                </Number>

                <Number>
                    <a>529 k</a>
                    <p>Comissão paga</p>
                </Number>

                <Number>
                    <a>13 M</a>
                    <p>Total recebido</p>
                </Number>
                
                <Number>
                    <a>100</a>
                    <p>Corretores impactados</p>
                </Number>
                
            </NumbersList>

        </Container>
    )
}
  
export default Numbers
  