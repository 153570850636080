import styled from 'styled-components';

export const Container = styled.section`
    background: #F8F9FA;
    display: flex;
    height: 180px;
    
    @media (max-width: 768px) {
        height: auto;
        flex-flow: wrap;
        height: 455px;
    }
`;

export const Title = styled.div`
  
    display: block;
    margin: auto 16vh auto 19vh;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex: 1;
    max-width: 133px;
    
    @media (max-width: 768px) {
      max-width: 375px;
      margin: auto auto;  
    }

    h1 {
      
      font-family: "Avenir", Avenir, Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 38px;

      color: #0E222B;

      @media (max-width: 768px) {
        white-space: nowrap;
      }
    }
`;

export const NumbersList = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 0;

    @media (max-width: 768px) {
      margin: 0 40px 30px;
    }
    
`;

export const Number = styled.div`
  display: block;
  width: 99px;
  height: 120px;

  margin: auto 60px;

  @media (max-width: 768px) {
    margin: auto 30px;
  }

  @media (max-width: 375px) {
    margin:auto 20px;
  }

  @media (max-width: 320px) {
    margin:auto 10px;
  }


  a {

    font-family: Montserrat;
    font-size: 40px;
    line-height: 40px;
    color: #173746;
    white-space: nowrap;

    border-bottom: 4px solid #80F5E4; 
    padding-bottom: 12px;

  }

  p {
    margin-top: 28px;

    font-family: Roboto;
    font-size: 16px;
    line-height: 25px;
    white-space: nowrap;

    @media (max-width: 768px) { 
      white-space: normal;
    }
  }
`; 